.ant-pagination {
  padding-top: 16px !important;
}

.ant-pagination-item {
  border-radius: 8px;
  margin-right: 4px !important;
}

.ant-pagination-item-link {
  border-radius: 8px !important;
}

.ant-card-extra {
  margin: unset;
}

.menu-bottom {
  position: fixed;
  padding: 16px;
  display: none;
  align-items: center;

  bottom: 0;

  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  left: 0;
  width: 100%;
  background-color: #fafafa;
  a {
    color: rgba(0, 0, 0, 0.85);
  }
  z-index: 10;
  svg {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 576px) {
    display: flex;
  }
}

.icon-drwaer {
  position: fixed;
  background-color: #494640;
  padding: 4px 8px;
  z-index: 100;
  left: 0;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.051),
    0 6px 16px 0 rgba(0, 0, 0, 0.078), 0 3px 6px -4px rgba(0, 0, 0, 0.122);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 8px 8px 0px;
  color: white;
}
.icon-drwaer p {
  font-weight: 600;
  color: white;
  margin-right: 4px;
  margin-bottom: 0;
}
.icon-drwaer svg {
  width: 14px;
  height: 14px;
}

.drawer_profile {
  .ant-menu {
    width: 100% !important;
  }
  .ant-menu-item {
    padding: 0 8px !important;
  }
  .ant-menu-title-content {
    opacity: 1 !important;
  }
  span {
    color: black !important;
  }
}

.ant-card-head-wrapper {
  flex-wrap: wrap;
}

.ant-card-body {
  min-height: 68px;
}

.ant-checkbox + span {
  padding: 0 4px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.richtext img {
  object-fit: contain;
  width: 100%;
}

.ant-card-body {
  min-height: auto;
}

.checkbox-custom {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  justify-content: center;
}
.checkbox-custom .ant-checkbox-checked::after {
  border-radius: 999px !important;
}
.checkbox-custom .ant-checkbox-wrapper {
  border-radius: 8px;
  border: 1px solid #00000010;
  padding: 4px;
  background: #f5f0f0;
}
.checkbox-custom .ant-checkbox-inner {
  border-radius: 999px;
}
.checkbox-custom span {
  font-size: 12px;
}

.modal-custom {
  .ant-modal-body {
    padding: 0 12px;
    padding-top: 8px;
  }
}
