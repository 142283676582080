@import '~antd/dist/antd.css';
@import './custom.css';
.headerLogo {
  height: 32px;
  width: 100px;
  margin: 16px 0px;
  background: rgba(255, 255, 255, 0.2);
  flex: 1;
}
.headerMenu {
  flex: 7;
}
.color1_ {
  background-image: linear-gradient(
    45deg,
    hsl(240deg 100% 72%) 0%,
    hsl(279deg 84% 66%) 11%,
    hsl(311deg 90% 64%) 22%,
    hsl(323deg 100% 64%) 33%,
    hsl(336deg 100% 66%) 44%,
    hsl(356deg 100% 71%) 56%,
    hsl(20deg 100% 66%) 67%,
    hsl(36deg 100% 59%) 78%,
    hsl(48deg 100% 50%) 89%,
    hsl(55deg 100% 50%) 100%
  ) !important;
  color: white;
}
.color1 {
  background-image: linear-gradient(
    45deg,
    hsl(240deg 100% 72%) 0%,
    hsl(279deg 84% 66%) 11%,
    hsl(311deg 90% 64%) 22%,
    hsl(323deg 100% 64%) 33%,
    hsl(336deg 100% 66%) 44%,
    hsl(356deg 100% 71%) 56%,
    hsl(20deg 100% 66%) 67%,
    hsl(36deg 100% 59%) 78%,
    hsl(48deg 100% 50%) 89%,
    hsl(55deg 100% 50%) 100%
  ) !important;
  border: none;
  color: white;
  border-radius: 20px;
  font-weight: 500;
}

.color1:hover,
.color2:hover {
  box-shadow: 0px 15px 20px rgba(121, 115, 115, 0.4);
  color: white;
  transform: translateY(-7px);
}
.color1:focus,
.color2:focus {
  color: black;
  background: rgb(201 201 201) !important;
}
.color1:active,
.color2:active {
  color: black;
  background: rgb(201 201 201) !important;
}
.color2 {
  background-image: linear-gradient(
    to right top,
    #0464f6,
    #009eff,
    #00c4e7,
    #00df86,
    #a8eb12
  ) !important;
  color: white;
  border-radius: 20px;
  border: none;
  font-weight: 500;
}
.color2_ {
  background-image: linear-gradient(
    to right top,
    #0464f6,
    #009eff,
    #00c4e7,
    #00df86,
    #a8eb12
  ) !important;
  color: white;
}
.ant-card-extra {
  padding: 10px 0;
}
.ant-card-head-title {
  padding: 10px 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 400px;
}
.center2 {
  position: absolute;
  top: 10%;
  left: 50%;

  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 400px;
}
.center3 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 20px;
  width: 900px;
}
.centerText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 400px;
  text-align: center;
}
.container {
  width: 100%;
  height: 100%;
  flex: 1;
  padding: 10px;
  min-height: calc(100vh - 210px);
}
.pdL {
  padding-left: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.pdR {
  padding-right: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.pdB {
  margin-bottom: 10px;
  width: 100%;
}
.site-button-ghost-wrapper {
  padding: 26px 16px 16px;
  background: rgb(190, 200, 200);
}
.bootingSelect > .ant-select-selector {
  border-radius: 10px !important;
  background-color: #e9edf3 !important;
  color: #343f52 !important;
  box-shadow: none !important;
  height: 40px !important;
  padding-top: 5px !important;
  padding-left: 20px !important;
}
.wheel {
  display: inline-block;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 800px;
  /* margin-top: 3%; */
  transition: transform 5s cubic-bezier(0.5, 0.1, 0.15, 1);
  transform: rotate(0deg);
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 768px) {
  .wheel-wrapper {
    width: 300px;
    height: 300px;
    position: relative;
    margin: 0 auto;
  }
  .wheel-pointer {
    width: 90px;
    height: 90px;
    background: url('../../public/assets/images/pointpng.png');
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 60px;
    z-index: 10;
  }
  .wheel-bg {
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    overflow: hidden;
    transition: transform 4s ease-in-out;
    background-size: 100% 100% !important;
  }
  .wheel-bg.freeze {
    transition: none;
    background: red;
  }
  .prize-list {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
  }
  .prize-item-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 150px;
  }
  .prize-item {
    width: 100%;
    height: 100%;
    transform-origin: bottom;
  }
  .prize-item .prize-name {
    padding: 14px 0;
    font-weight: 700;
  }
}

@media (min-width: 769px) {
  .wheel-wrapper {
    width: 450px;
    height: 450px;
    position: relative;
    margin: 0 auto;
  }
  .wheel-pointer {
    width: 122px;
    height: 122px;
    background: url('../../public/assets/images/pointpng.png');
    background-size: 71%;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  .wheel-bg {
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    overflow: hidden;
    transition: transform 4s ease-in-out;
    background-size: 100% 100% !important;
  }
  .wheel-bg.freeze {
    transition: none;
    background: red;
  }
  .prize-list {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
  }
  .prize-item-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 250px;
  }
  .prize-item {
    width: 100%;
    height: 100%;
    transform-origin: bottom;
  }
  .prize-item .prize-name {
    padding: 14px 0;
    font-weight: 700;
  }
}
.wheel-pointer {
  opacity: 0.8;
}
.wheel-pointer:hover {
  opacity: 1;
}
.green {
  background: #8bc34a;
  color: white;
  /* border: none; */
}

.menu_cpanel.ant-menu-inline-collapsed {
  width: 40px;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}
/* width */
.ant-table-body::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: #f5f5f5;
}

/* Track */
.ant-table-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

/* Handle */
.ant-table-body::-webkit-scrollbar-thumb {
  background-color: rgb(87, 87, 87);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

main::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: #f5f5f5;
}

/* Track */
main::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

/* Handle */
main::-webkit-scrollbar-thumb {
  background-color: rgb(87, 87, 87);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
@media (max-width: 768px) {
  .ant-card-extra {
    width: 100%;
  }
}
.ant-upload.ant-upload-select-picture-card {
  height: 115px;
  width: 100%;
}
.rdw-editor-wrapper {
  box-sizing: content-box;
  border: 1px solid #cccc;
  padding: 10px;
}
.ant-upload-select-picture {
  width: 100%;
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 160px;
  margin-right: 8px;
}
.upload-list-inline_mobile .ant-upload-list-item {
  float: left;
  width: 100%;
}
.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}
.btn-default {
  -bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #ccc;
  padding: 8px;

  border-radius: 5px;
}
.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 10px;
  border-radius: 5px;
}
.btn-danger:hover {
  color: white;
}
.mobile_menu > button {
  text-align: left;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #dddc;
  padding-bottom: 30px;
}
.ant-input[disabled] {
  color: rgb(0 0 0 / 78%);
}

.wi-100 {
  width: 100%;
}
.bLeft {
  width: 49%;
  margin-left: 1%;
}
.bRight {
  width: 49%;
  margin-right: 1%;
}
.gx-w-100 {
  width: 100%;
}
.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.edit-tag {
  user-select: none;
}
.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}
.rdw-dropdown-selectedtext {
  color: black;
}
.topStatis > .ant-statistic-title {
  margin-bottom: 4px;
  color: rgb(255 255 255);
  font-size: 14px;
}
.ant-card-actions > li {
  align-self: center;
}
.rdw-editor-wrapper {
  border: none !important;
  padding: 0px;
}
.wrapperClassName {
  border: 1px solid #cccc !important;
  padding: 10px;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  font-size: 13px;
}
.treenodeW > div > .ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: initial;
  max-width: 100%;
  overflow: hidden;
  height: 60px;
}
.ant-select-dropdown {
  z-index: 9999 !important;
}
.ant-select-item-option-content {
  font-size: 12px;
}
